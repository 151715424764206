<template>
  <div class="container-fluid">
    <h4>Filter:</h4>
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
          <div class="col-4" :key="weight._id" v-for="weight in weights">
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                class="form-check-input"
                :id="weight._id"
                :value="weight.type"
                v-model="selectedWeights"
                :disabled="loading"
              />
              <label class="form-check-label" :for="weight._id">{{
                weight.type
              }}</label>
            </div>
          </div>
          <div class="col-4">
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                id="d123"
                class="form-check-input"
                :checked="selectedWeights.length === weights.length"
                @click="toggleAllWeights()"
                :disabled="loading"
              />
              <label class="form-check-label" for="d123">Alle auswählen</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title text-center text-danger">
              Vorlaufzeit berücksichtigen
            </h5>
            <div class="card-text">
              <div class="row">
                <div class="col">
                  <strong>Edelstahl 1500:</strong>
                </div>
                <div class="col">1 Tag Vorlauf</div>
              </div>
              <div class="row">
                <div class="col">
                  <strong>Edelstahl 1500 (Pharma):</strong>
                </div>
                <div class="col">2 Tage Vorlauf</div>
              </div>
              <div class="row">
                <div class="col">
                  <strong>Edelstahl 3000 (Pharma):</strong>
                </div>
                <div class="col">2 Tage Vorlauf & 1 Tag Nachlauf</div>
              </div>
              <div class="row">
                <div class="col">
                  <strong>Sonstige Stücklung:</strong>
                </div>
                <div class="col">1 Tag Vorlauf & 1 Tag Nachlauf</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <FullCalendar
          :options="calendarOptions"
          ref="fullCalendar"
        ></FullCalendar>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { getWeights, getAvailableWeights } from '../../api';
import { eachDayOfInterval, getTime } from 'date-fns';

export default {
  name: 'availability',
  components: {
    FullCalendar,
  },
  watch: {
    selectedWeights: function () {
      this.$refs.fullCalendar.getApi().refetchEvents();
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, bootstrapPlugin],
        initialView: 'dayGridDay',
        locale: 'de',
        firstDay: '1',
        weekNumbers: true,
        headerToolbar: {
          start: '',
          center: 'title',
          end: 'dayGridMonth,dayGridWeek,dayGridDay today prev,next',
        },
        eventOrder: (a, b) => {
          if (a.weight.type > b.weight.type) {
            return 1;
          }

          return -1;
        },
        navLinks: true,
        buttonText: {
          today: 'Heute',
          month: 'Monat',
          week: 'Woche',
          day: 'Tag',
        },
        eventDisplay: 'block',
        events: this.fetchWeights,
        dayMaxEventRows: true,
        views: {
          dayGridMonth: {
            dayMaxEventRows: 6,
          },
        },
        eventTextColor: 'white',
        displayEventTime: false,
        themeSystem: 'bootstrap',
      },

      loading: false,
      selectedWeight: '',
      selectedWeights: [],
      dayView: false,
      fetchingResults: false,
      weights: [],
      filteredWeights: [],
    };
  },

  async created() {
    this.loading = true;
    this.weights = await getWeights();
    this.loading = false;
  },
  methods: {
    toggleAllWeights() {
      if (this.selectedWeights.length === this.weights.length) {
        this.selectedWeights = [];
      } else {
        this.selectedWeights = this.weights.map((weight) => weight.type);
      }
    },
    fetchWeights(info, successCb) {
      const interval = eachDayOfInterval({
        start: info.start,
        end: info.end,
      });

      const promises = [];

      interval.forEach((time) => {
        promises.push(
          getAvailableWeights({
            start: getTime(time.valueOf()),
            end: getTime(time.valueOf()),
          })
        );
      });

      Promise.all(promises).then((result) => {
        let events = [];

        result.forEach((r, index) => {
          let tempEvents = [];

          if (r.length) {
            tempEvents = r.map((weight) => {
              let backgroundColor = undefined;

              let available = 0;

              if (this.weights.find((w) => w.type === weight.type)) {
                available =
                  this.weights.find((w) => w.type === weight.type).amount -
                  weight.amount;
              }

              if (available <= 0) {
                backgroundColor = 'red';
              } else if (available === 1) {
                backgroundColor = 'orange';
              } else {
                backgroundColor = 'green';
              }

              return {
                title: `${weight.type} : ${available}`,
                start: interval[index],
                end: interval[index],
                weight,
                backgroundColor,
                borderColor: backgroundColor,
              };
            });
          } else {
            tempEvents = this.weights.map((weight) => {
              return {
                title: `${weight.type} : ${weight.amount}`,
                start: interval[index],
                end: interval[index],
                weight,
                backgroundColor: 'green',
                borderColor: 'green',
              };
            });
          }

          events.push(...tempEvents);
        });

        events = events.filter((event) =>
          this.selectedWeights.includes(event.weight.type)
        );

        events = events.filter((event) => {
          return (
            event.weight.type !== 'Hubwagen' &&
            event.weight.type !== 'Techniker Boxen' &&
            event.weight.type !== 'Edelstahl 200' &&
            event.weight.type !== 'Edelstahl 100' &&
            event.weight.type !== 'Guss 1500'
          );
        });
        successCb(events);
      });
    },
  },
};
</script>
